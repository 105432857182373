<template>
  <div
    class="card dark:bg-gray-900 bg-white"
    :class="[
      {
        [$props.color]: $props.color,
        [`${$props.width}Width`]: $props.width,
        [`padding-${$props.padding}`]: $props.padding,
        [`elevation-${$props.elevation}`]: $props.elevation,
        round: $props.round,
        border: $props.border,
      },
    ]"
  >
    <slot />
  </div>
</template>

<script>
/**
 * @group Base
 * Functional component, renders a card whose content is displayed in a flex column
 */
export default {
  props: {
    padding: {
      type: String,
      default: 'none',
      validator (value) {
        // The value must match one of these strings or be empty
        const sizes = ['none', 'xsmall', 'small', 'medium', 'large', 'xlarge']
        return sizes.includes(value)
      },
    },
    // Set the background colour of the card
    color: {
      type: String,
      default: '',
    },
    // Sets a border radius of 2.1rem
    round: {
      type: Boolean,
      default: false,
    },
    border: {
      type: Boolean,
      default: true,
    },
    // Set the box shadow
    elevation: {
      type: [String, Number],
      default: 0,
      validator (value) {
        if (value === 0) {
          return true
        }
        // The value must match one of these strings or be empty
        const sizes = ['elevation-1', 'elevation-2', 'elevation-3']
        return sizes.includes(`elevation-${value}`)
      },
    },
    // Sets the the width of the card
    width: {
      type: String,
      default: '',
      validator (value) {
        // The value must match one of these strings or be empty
        if (value === '') {
          return true
        }
        const sizes = ['thirdWidth', 'halfWidth', 'twoThirdsWidth', 'fullWidth']
        return sizes.includes(`${value}Width`)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.light {
  .card.border {
    border: 0.1rem solid #e6e8ed;
  }
}

.dark {
  .card.border {
    border: 0.1rem solid transparent;
  }
}
.card {
  display: flex;
  flex-direction: column;

  border-radius: 0.4rem;

  @include breakpoint_up(lg) {
    &.thirdWidth {
      flex: 1 1 33.33%;
      max-width: calc(33.33% - 2 *#{$gap-lMd});
    }

    &.halfWidth {
      flex-basis: 50%;
      max-width: calc(50% - 2 *#{$gap-lMd});
    }

    &.twoThirdsWidth {
      flex-basis: 66.66%;
      max-width: calc(66.66% - 2 *#{$gap-lMd});
    }

    &.fullWidth {
      flex-basis: 100%;
      max-width: 100%;
    }
  }
}

.card.round {
  border-radius: 0.9rem;
}

.card.round :deep(> :first-child) {
  border-top-left-radius: 0.9rem;
  border-top-right-radius: 0.9rem;
}

.card.round :deep(> :last-child) {
  border-bottom-left-radius: 0.9rem;
  border-bottom-right-radius: 0.9rem;
}

.dark {
  .card :deep(> *) {
    color: white;
  }
}

.card :deep(> *) {
  // background-color: #fff;
  width: 100%;
}
</style>
